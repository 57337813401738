import React, {useEffect, useState} from "react";
import axios from "../../axios";
import {toast, ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import Loader from "../../components/Loader";
import {Link} from "react-router-dom";
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';

function Distributor() {
    const [distributorList, setDistributorList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [first, setFirst] = useState(0);
    const [rows, setRows] = useState(10);
    const [totalRecords, setTotalRecords] = useState(0);
    const [globalFilter, setGlobalFilter] = useState('');

    useEffect(() => {
        setLoading(true);
        fetchData();
    }, [first, rows, globalFilter]);

    const fetchData = () => {
        const currentPage = Math.floor(first / rows) + 1;
        axios.get(`/get_distributor?page=${currentPage}&pageSize=${rows}&globalFilter=${globalFilter}`)
            .then((response) => {
                setDistributorList(response.data.data);
                setTotalRecords(response.data.totalRecords);
            })
            .catch((error) => {
                toast.error('Something went wrong.');
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const onPage = (event) => {
        const newFirst = event.first;
        setFirst(newFirst);
        setRows(event.rows);
    };

    const indexTemplate = (rowData, column) => {
        return (
            <React.Fragment>
                {first + distributorList.indexOf(rowData) + 1}
            </React.Fragment>
        );
    };


    const filteredData = distributorList.filter((item) =>
        Object.values(item).some(
            (val) =>
                val &&
                val.toString().toLowerCase().includes(globalFilter.toLowerCase())
        )
    );

    return(
        <div className="container-fluid">
            <div className="row">
                <div className="col-xl-12">
                    <div className="card">
                        <div className="card-body">
                            <div className="table-responsive">
                                <h4 className="card-title">Distributor List</h4>
                                <div className="row">
                                    <div className="col-lg-3 offset-lg-9">
                                        <InputText
                                            placeholder="Search..."
                                            className="form-control"
                                            value={globalFilter}
                                            onChange={(e) => setGlobalFilter(e.target.value)}
                                        />
                                    </div>
                                </div>
                                <div className="table-responsive">
                                    <div className="table-responsive mt-3">
                                        <DataTable
                                            value={filteredData}
                                            loading={loading}
                                            paginator
                                            rows={rows}
                                            totalRecords={totalRecords}
                                            lazy
                                            onPage={onPage}
                                            first={first}
                                            tableStyle={{ minWidth: '85rem' }}
                                        >
                                            <Column header="#" body={indexTemplate} className="text-center"></Column>
                                            <Column field="company" header="Company" className="text-capitalize"></Column>
                                            <Column field="website" header="Website" body={(rowData) => (
                                                <Link to={rowData.website} target={"_blank"}>
                                                    {rowData.website}
                                                </Link>
                                            )}></Column>
                                            <Column field="email" header="Email"></Column>
                                            <Column field="country" header="Country"></Column>
                                            <Column field="your_position" header="Your Position" className="text-capitalize"></Column>
                                            <Column field="are_you_a" header="Are You A" className="text-capitalize" style={{ width: '3rem' }}></Column>
                                            <Column header="Action" body={(rowData) => (
                                                <React.Fragment>
                                                    <Link to={`/distributor/${rowData.id}`} className="btn btn-light btn-sm" style={{marginRight: '6px'}}>View</Link>
                                                    <Link to={`/distributor/edit/${rowData.id}`} className="btn btn-outline-primary btn-sm">Edit</Link>
                                                </React.Fragment>
                                            )}></Column>
                                        </DataTable>
                                        {loading && <Loader />}
                                        <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Distributor;