import React, {useEffect, useState} from 'react';
import {Route, Routes, useNavigate} from 'react-router-dom';
import axios from './axios';
import Login from "./pages/Login";
import Register from "./pages/Register";
import Layout from "./components/Layout";
import Dashboard from "./pages/Dashboard";
import Franchisee from "./pages/Franchisee/Franchisee";
import FranchiseeDetail from "./pages/Franchisee/FranchiseeDetail";
import FranchiseeEdit from "./pages/Franchisee/FranchiseeEdit";
import Distributor from "./pages/Distributor/Distributor";
import DistributorDetail from "./pages/Distributor/DistributorDetail";
import DistributorEdit from "./pages/Distributor/DistributorEdit";

function App() {
    const [authenticated, setAuthenticated] = useState(false);
    const navigate = useNavigate();

    // Check if the user is already authenticated (e.g., with a stored token) on app load
    useEffect(() => {
        const token = localStorage.getItem('token');
        if (token) {
            axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
            setAuthenticated(true);
        } else {
            navigate('/login');
        }
    }, [navigate, authenticated]);

    return (
        <>
            {authenticated ? (
                <Layout>
                    <Routes>
                        {/*<Route path="/" element={ <Dashboard /> } />*/}
                        <Route path="/dashboard" element={ <Dashboard /> } />
                        <Route path="/franchisee" element={ <Franchisee /> } />
                        <Route path="/franchisee/:id" element={ <FranchiseeDetail /> } />
                        <Route path="/franchisee/edit/:id" element={ <FranchiseeEdit /> } />
                        <Route path="/distributor" element={ <Distributor /> } />
                        <Route path="/distributor/:id" element={ <DistributorDetail /> } />
                        <Route path="/distributor/edit/:id" element={ <DistributorEdit /> } />
                    </Routes>
                </Layout>
            ) : (
                <Routes>
                    <Route path="/login" element={<Login />} />
                    <Route path="/register" element={<Register />} />
                </Routes>
            )}
        </>
    );
}

export default App;
