import React from "react";
import {Link, useLocation} from "react-router-dom";

function Nav(){
    const location = useLocation();

    return(
        <div className="vertical-menu">
            <div data-simplebar className="h-100">
                <div id="sidebar-menu">
                    <ul className="metismenu list-unstyled" id="side-menu">
                        <li className="menu-title" key="t-menu">Menu</li>
                        <li className={location.pathname === '/dashboard' ? 'mm-active' : ''}>
                            <Link to="/dashboard" className="waves-effect">
                                <i className="bx bx-home-circle"></i>
                                <span key="t-dashboards">Dashboards</span>
                            </Link>
                        </li>
                        <li className={location.pathname === '/franchisee' ? 'mm-active' : ''}>
                            <Link to="/franchisee" className="waves-effect">
                                <i className="bx bx-store"></i>
                                {/*<i className="fas fa-store-alt"></i>*/}
                                <span key="t-dashboards">Franchisee</span>
                            </Link>
                        </li>
                        <li className={location.pathname === '/distributor' ? 'mm-active' : ''}>
                            <Link to="/distributor" className="waves-effect">
                                <i className="bx bx-car"></i>
                                {/*<i className="fas fa-shuttle-van"></i>*/}
                                <span key="t-dashboards">Distributor</span>
                            </Link>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    );
}

export default Nav;